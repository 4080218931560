/* eslint-disable react/jsx-pascal-case */
import Head from "next/head";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { GetStaticProps } from "next";
import { KeyVisual } from "@/components/Top/KeyVisual";
import { Layout } from "@/components/Layout";
import {
  Collapse,
  Title,
  Text,
  Container,
  UnstyledButton,
} from "@mantine/core";
import { useState } from "react";
import { getAssetUrl, defaultRoutes } from "@/utils";
import { useDisclosure } from "@mantine/hooks";
import dynamic from "next/dynamic";
import Image from "next/image";
import OrganizationJsonLd from "@/components/JsonLd/Organization";
import WebsiteJsonLd from "@/components/JsonLd/WebSite";
import BreadcrumbListJsonLd from "@/components/JsonLd/BreadcrumbList";
import WebpageJsonLd from "@/components/JsonLd/WebPage";

const PopularTickets = dynamic(() => import("@/components/Top/PopularTickets"));
const HowToOrder = dynamic(() => import("@/components/Top/HowToOrder"));
const FAQ = dynamic(() => import("@/components/Top/FAQ"));
const About = dynamic(() => import("@/components/Top/About"));
const SectionItem = dynamic(() => import("@/components/SectionItem"));
const AreaRouteFooter = dynamic(
  () => import("@/components/Top/AreaRouteFooter"),
);
const ClaspoWidget = dynamic(() => import("@/components/Top/ClaspoWidget"));
const GroupService = dynamic(() => import("@/components/Top/GroupService"));
// const ColumnNews = dynamic(() => import("@/components/Top/ColumnNews"));
const GmEsimAppBanner = dynamic(
  () => import("@/components/Top/GroupService/GmEsimAppBanner"),
);
const StickyFooter = dynamic(() => import("@/components/Top/StickyFooter"));

export default function App(): JSX.Element {
  const { t, i18n } = useTranslation();

  const [opened, { toggle }] = useDisclosure(false);
  const breadcrumbs = [{ title: t("common:service.title"), href: "/" }];

  const [searchRef, setSearchRef] =
    useState<React.RefObject<HTMLDivElement> | null>(null);

  return (
    <>
      <Head>
        <title>{t("meta:home.title")}</title>
        <meta content={t("meta:home.description")} name="description" />
        <meta content="width=device-width, initial-scale=1" name="viewport" />
        <link href="/favicon.ico" rel="icon" />
      </Head>
      <Layout>
        <OrganizationJsonLd />
        <WebsiteJsonLd />
        <BreadcrumbListJsonLd breadcrumbs={breadcrumbs} />
        <WebpageJsonLd
          name={t("meta:home.title")}
          description={t("meta:home.description")}
          pathname=""
        />
        <KeyVisual
          title={
            <Title
              order={1}
              className="!leading-[38px] !text-blue-9 text-2xl md:text-2.75xl"
            >
              {t("top:kv.title")}
            </Title>
          }
          setSearchRef={setSearchRef}
          pcImg="/japan-bullet-train.webp"
          spImg="/kv_sp.webp"
          isCarousel
        />
        <Container className="mt-10 mx-auto">
          <UnstyledButton
            onClick={toggle}
            className="w-[343px] md:w-[928px] h-[111px] md:h-[118px]"
          >
            <picture>
              <source
                media="(max-width: 767px)"
                srcSet={getAssetUrl(`/banners/esim/${i18n.language}-sp.webp`)}
              />
              <source
                media="(min-width: 768px)"
                srcSet={getAssetUrl(`/banners/esim/${i18n.language}-pc.webp`)}
              />
              <Image
                src={getAssetUrl(`/banners/esim/${i18n.language}-pc.webp`)}
                alt="eSIM"
                width={1020}
                height={130}
                className="h-full w-full object-cover object-bottom"
              />
            </picture>
          </UnstyledButton>
          <Collapse in={opened}>
            <Text className="whitespace-pre-line">
              {t("top:banners.free_esim", { joinArrays: "\n" })}
            </Text>
          </Collapse>
        </Container>

        {/* NOTE: Temporarily hiding code per ticket: 51232 */}
        {/* <SectionItem
          title={t("column:categories.news")}
          className="!pb-0"
          titleUrlLabel={t("common:see_more")}
          titleUrl="/column"
          titleClass="uppercase"
        >
          <ColumnNews news={news} />
        </SectionItem> */}
        <SectionItem title={t("top:sections.popular_tickets.title")}>
          <PopularTickets routes={defaultRoutes} hasImg />
        </SectionItem>
        <GmEsimAppBanner />
        <SectionItem
          title={t("top:sections.boarding.title")}
          className="bg-dark-3"
          titleUrl={t("top:sections.boarding.link")}
          titleClass="uppercase"
        >
          <HowToOrder />
        </SectionItem>
        <FAQ />
        <About
          title={t("top:sections.faq.info.title")}
          description={t("top:sections.faq.info.description", {
            joinArrays: "\n\n",
          })}
        />
        <SectionItem
          title={t("top:group_services.title")}
          className="bg-dark-3"
          bg="dark.1"
        >
          <GroupService />
        </SectionItem>
        <SectionItem
          title={t("area:common.links.title")}
          className="bg-dark-3"
          bg="dark.1"
        >
          <AreaRouteFooter />
        </SectionItem>
        <ClaspoWidget />
      </Layout>
      <StickyFooter searchRef={searchRef} />
    </>
  );
}

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const translations = await serverSideTranslations(locale ?? "en", [
    "meta",
    "common",
    "top",
    "faq",
    "locations",
    "area",
    "column",
  ]);

  // NOTE: Temporarily hiding code per ticket: 51232
  // const news = await microCmsClient.getList<IContent>({
  //   endpoint: "column",
  //   queries: {
  //     fields: "id,title,publishedAt",
  //     filters: "category[contains]news",
  //     limit: 3,
  //   },
  // });

  return {
    props: {
      ...translations,
      // news: news.contents,
    },
  };
};
