import { APP_URL } from "@/constants";
import { generateJsonLd, getCurrentLocaleUrl } from "@/utils";
import { useTranslation } from "next-i18next";

export default function WebsiteJsonLd() {
  const { i18n } = useTranslation();
  const currentLocale = getCurrentLocaleUrl(i18n.language);
  const jsonLdWebsite = generateJsonLd("WebSite", i18n.language, {
    name: "Japan Bullet Train",
    url: `${APP_URL}/${currentLocale}`,
  });

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(jsonLdWebsite),
      }}
    />
  );
}
