import { APP_URL } from "@/constants";
import { generateJsonLd, getCurrentLocaleUrl } from "@/utils";
import { useTranslation } from "next-i18next";

export default function OrganizationJsonLd() {
  const { i18n } = useTranslation();

  const currentLocale = getCurrentLocaleUrl(i18n.language);

  const jsonLdOrganization = generateJsonLd("Organization", i18n.language, {
    name: "Japan Bullet Train",
    url: `${APP_URL}/${currentLocale}`,
    logo: "https://cdn.japan-bullettrain.com/logo/tse-en.svg",
    sameAs: [
      "https://www.facebook.com/profile.php?id=61558820368463",
      "https://www.instagram.com/japanbullettrain/",
    ],
    contactPoint: {
      "@type": "ContactPoint",
      telephone: "+81-3-6820-0074",
      contactType: "Customer Service",
      areaServed: "JP",
      availableLanguage: [
        "English",
        "Chinese (Simplified)",
        "Korean",
        "Japanese",
        "Indonesian",
        "Spanish",
        "French",
        "Filipino/Tagalog",
        "Vietnamese",
      ],
    },
    address: {
      "@type": "PostalAddress",
      streetAddress: "Minato, Tokyo, Japan",
      addressLocality: "Tokyo",
      postalCode: "105-0004",
      addressCountry: "JP",
    },
  });

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(jsonLdOrganization),
      }}
    />
  );
}
